<template>
  <div>
    <div class="form_bottom_row clearfix">
      <div class="fr img_row" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
        <img src="@/assets/images/channel/swift.png" alt />
      </div>
      <div class="fl form_row">
        <el-form>
          <ul class="form_list clearfix">
            <li class="fl">
              <SelectForm
                v-model="TransferForm.selectedCardID"
                :label="$t('withdraw.default.selectBA')"
                name="selectedCard"
                testId="selectedCardID"
              >
                <el-option
                  v-for="item in availableCards"
                  :key="item.id"
                  :value="item.id"
                  :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                  :data-testid="`select_${item.id}`"
                ></el-option>
              </SelectForm>
            </li>
            <li class="fr" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
              <!-- Transfer country select-->
              <WithdrawlSharedOptions
                :label="chooseWithdrawalType.subTypeNameKey"
                v-model="transferCountryString"
                :list="chooseWithdrawalType.withdrawSubTypes"
                :disabled="TransferForm.selectedCardID !== -1"
              ></WithdrawlSharedOptions>
            </li>
          </ul>
        </el-form>
      </div>
    </div>
    <div>
      <component
        :is="currentComponent"
        ref="bankChild"
        :selectedCardInfo="selectedCardInfo"
        :transferCountryString="transferCountryString"
        :accountNumber="accountNumber"
        :amount="amount"
        :withdrawalType="withdrawalType"
        :userCountryCode="userCountryCode"
        :followerResults.sync="followerResults"
      ></component>
    </div>
  </div>
</template>

<script>
import TransferIntWithdraw from './TransferIntWithdraw';
import mixin from '@/mixins';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import WithdrawlSharedOptions from '@/components/form/WithdrawlSharedOptions';

export default {
  name: 'BankTransfer',
  components: { TransferIntWithdraw, WithdrawlSharedOptions },
  props: ['accountNumber', 'amount', 'withdrawalType', 'chooseWithdrawalType', 'userCountryCode', 'followerResults'],
  mixins: [mixin, withdrawlMixin],
  data() {
    return {
      currentComponent: null,
      transferCountry: null,
      selectedCardInfo: null,
      transferCountryString: null
    };
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      if (this.availableCards.length !== 0) {
        this.selectedCardInfo = this.availableCards.find(item => item.id === value);
        this.transferCountry = this.selectedCardInfo.country;
        this.transferCountryString = this.transferCountry ? this.transferCountry.toString() : null;
      }
    },
    transferCountryString(value) {
      switch (value) {
        case '2':
          this.currentComponent = TransferIntWithdraw;
          break;
        default:
          this.currentComponent = null;
          break;
      }
    }
  },
  methods: {
    submitWithdraw() {
      return this.$refs.bankChild.submitWithdraw();
    }
  }
};
</script>
