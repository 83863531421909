<template>
  <div id="japanForm" class="form_bottom_row line clearfix">
    <div class="fr img_row" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
      <img src="@/assets/images/channel/vietna.png" alt />
    </div>
    <div class="fl form_row">
      <el-form label-position="top" :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('common.withdrawChannel.banktransfer')"
              name="selectedCardID"
              testId="selectedCardID"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </SelectForm>
          </li>
          <li>
            <SelectForm
              v-model="TransferForm.withdrawCurrency"
              :label="$t('common.field.bankAccountCurrency')"
              name="withdrawCurrency"
            >
              <el-option
                v-for="currency in supportedCurrencies"
                :key="currency"
                :value="currency"
                :label="currency"
                :data-testid="currency"
              ></el-option>
            </SelectForm>
            <div class="rate" v-if="isJPY">
              <p class="tip">
                {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'JPY' }) }}
                <span>{{ rate }}</span>
              </p>
            </div>
          </li>
        </ul>

        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form_list clearfix">
            <li>
              <InputForm
                :label="$t('common.field.bankName')"
                name="bankName"
                v-model="TransferForm.bankName"
              ></InputForm>
              <p class="tip">{{ $t('deposit.japan.bankNameTip') }}</p>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankBranch')"
                name="bankBranchName"
                v-model="TransferForm.bankBranchName"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.accName')"
                name="accountName"
                v-model="TransferForm.accountName"
                :disabled="isdisabled"
              ></InputForm>
              <p class="tip">{{ $t('deposit.japan.accountNameTip') }}</p>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankAccNum')"
                name="accountNumber"
                v-model="TransferForm.accountNumber"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <SelectForm
                v-model="TransferForm.bankAccType"
                name="bankAccType"
                :label="$t('common.field.bankAccType')"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="item in bankAccTypeList"
                  :key="item.value"
                  :value="item.value"
                  :label="$t(item.label)"
                  :data-testid="item.value"
                ></el-option>
              </SelectForm>
            </li>
            <li v-if="this.isOtherBankAccType">
              <InputForm
                name="otherBankAccType"
                v-model="TransferForm.otherBankAccType"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <ImportantNotesInput
                :label="$t('common.field.imptNotes')"
                name="importantNotes"
                v-model="TransferForm.importantNotes"
              ></ImportantNotesInput>
            </li>
          </ul>

          <div class="checkbox_wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
              {{ $t('withdraw.default.remember') }}</el-checkbox
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { apiWithdrawalQueryRateUSDByTargetCurrency } from '@/resource';
import mixin from '@/mixins';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'japanWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    bankList: Object
  },
  components: { ImportantNotesInput },
  mixins: [mixin, withdrawlMixin, bankTransferMixin],
  data() {
    return {
      TransferForm: {
        bankName: '',
        bankBranchName: '',
        accountName: '',
        accountNumber: '',
        bankAccType: '',
        otherBankAccType: '',
        importantNotes: '',
        userPaymentInfoId: null,
        withdrawCurrency: ''
      },
      isJPY: false,
      supportedCurrencies: ['JPY', 'USD'],
      rate: '',
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchReq'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        accountName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.accName')
            }),
            trigger: ['blur', 'change']
          }
        ],
        bankAccType: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.bankAccType')
            }),
            trigger: ['blur', 'change']
          }
        ],
        otherBankAccType: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.bankAccType')
            }),
            trigger: ['blur', 'change']
          }
        ],
        withdrawCurrency: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.bankAccountCurrency')
            }),
            trigger: ['blur', 'change']
          }
        ]
      },
      bankAccTypeList: [
        { label: 'deposit.japan.futsu', value: '普通' },
        { label: 'deposit.japan.touza', value: '当座' },
        { label: 'deposit.japan.chochiku', value: '貯蓄' },
        { label: 'deposit.japan.others', value: 'その他' }
      ]
    };
  },
  computed: {
    isOtherBankAccType() {
      return this.TransferForm.bankAccType === 'その他';
    }
  },
  mounted() {
    this.queryRate();
  },
  methods: {
    queryRate() {
      apiWithdrawalQueryRateUSDByTargetCurrency('JPY')
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'JPY' })
          );
        });
    },
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName,
        bankBranchName: this.TransferForm.bankBranchName,
        accountNumber: this.TransferForm.accountNumber,
        accountName: this.TransferForm.accountName,
        bankAccType: this.TransferForm.bankAccType,
        otherBankAccType: this.TransferForm.otherBankAccType,
        importantNotes: this.TransferForm.importantNotes,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        targetCurrency: this.TransferForm.withdrawCurrency
      };
    }
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      const isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;

      this.TransferForm = {
        selectedCardID: value,
        bankName: selectedCardInfo.bankName || '',
        bankBranchName: selectedCardInfo.bankBranchName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        accountName: selectedCardInfo.accountName || '',
        bankAccType: selectedCardInfo.bankAccType || '',
        otherBankAccType: selectedCardInfo.otherBankAccType || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null,
        targetCurrency: selectedCardInfo.withdrawCurrency || ''
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'TransferForm.withdrawCurrency'(value) {
      if (value === 'JPY') this.isJPY = true;
      else this.isJPY = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.form_row {
  ul {
    li {
      p.tip {
        top: -30px;
        position: relative;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
