<template>
  <div class="l_select" :value="$attrs.value" @click.capture="visible = true" :class="{ active: visible }">
    <el-form-item :label="label" :prop="name">
      <el-select
        v-model="withdrawalType"
        v-on="listeners"
        v-bind="$attrs"
        class="showPlaceholder"
        :class="{ selected: listeners, creditCard: name === 'creditCard', ccReset: reset }"
        :disabled="disabled || reset"
        :data-testid="testId || name"
      >
        <el-option v-for="item in availableTypes" :key="item.id" :value="item.id">
          <div>
            <img class="type_Img" :src="withdrawalTypesMap[item.id].img" alt />
            <div class="select_text">{{ $t(item.nameKey) }}</div>
          </div>
        </el-option>
        <template v-if="listeners" #prefix>
          <div v-if="chooseWithdrawalType">
            <img class="type_Img" :src="withdrawalTypesMap[chooseWithdrawalType.id].img" alt />
            <div class="select_text" :class="{ small_text: [5, 6, 34, 36].includes(chooseWithdrawalType.id) }">
              <p>{{ $t(chooseWithdrawalType.nameKey) }}</p>
            </div>
            <div class="amount" :class="{ mr_amount: ['zh_CN', 'ja', 'ko', 'vi'].includes(lang) }">
              {{ amount }}
            </div>
          </div>
          <div v-else-if="showCreditCard">
            <img class="type_Img" src="@/assets/images/withdraw/master_card.png" alt />
            <div class="select_text">
              {{ $t('common.withdrawChannel.creditcard') }}
              <i class="el-icon-info" :title="$t('withdraw.default.remainingWithdraw')"></i>
            </div>
            <div class="amount" :class="{ mr_amount: ['zh_CN', 'ja', 'ko', 'vi'].includes(lang) }">
              {{ amount }}
            </div>
          </div>
          <div v-else class="select_text">
            {{ selectText }}
          </div>
        </template>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import ThaMalVieWithdraw from './ThaMalVieWithdraw';
import IndiaWithdraw from './IndiaWithdraw';
import UnionPayWithdrawCps from './UnionpayWithdrawCps';
import CreditCardWithdraw from './CreditCardWithdraw';
import FasapayWithdraw from './FasapayWithdraw';
import NetellerWithdraw from './NetellerWithdraw';
import SkrillWithdraw from './SkrillWithdraw';
import BitcoinWithdraw from './BitcoinWithdraw';
import LaosWithdraw from './LaosWithdraw';
import DasshpeWithdraw from './DasshpeWithdraw';
import BankTransfer from './BankTransfer';
import UsdtWithdraw from './UsdtWithdraw';
import IndonesiaWithdraw from './IndonesiaWithdraw.vue';
import HongKongWithdraw from './HongKongWithdraw.vue';
import PhilipinesWithdraw from './PhilipinesWithdraw';
import PerfectMoneyWithdraw from './PerfectMoneyWithdraw';
import MexicoAndBrazilWithdraw from './MexicoAndBrazilWithdraw';
import LocalDepositorWithdraw from './LocalDepositorWithdraw';
import WiseWithdraw from './WiseWithdraw';
import JapanWithdraw from './JapanWithdraw';

export default {
  name: 'selectWithdraw',
  props: {
    name: String,
    label: String,
    testId: String,
    availableTypes: Array,
    amount: Number,
    disabled: Boolean,
    reset: Boolean,
    showCreditCard: {
      type: Boolean,
      default: false
    },
    selectText: String
  },
  data() {
    return {
      visible: false,
      withdrawalType: null,
      withdrawalTypesMap: {
        1: {
          component: CreditCardWithdraw,
          img: require('@/assets/images/withdraw/credit_img.jpg')
        },
        2: {
          component: BankTransfer,
          img: require('@/assets/images/channel/swift.png')
        },
        4: {
          component: UnionPayWithdrawCps,
          img: require('@/assets/images/withdraw/select_unionpay.png')
        },
        5: {
          component: ThaMalVieWithdraw,
          img: require('@/assets/images/withdraw/select_thailand.png')
        },
        6: {
          component: ThaMalVieWithdraw,
          img: require('@/assets/images/withdraw/select_vietna.png')
        },
        7: {
          component: FasapayWithdraw,
          img: require('@/assets/images/withdraw/select_fasapay.png')
        },
        8: {
          component: ThaMalVieWithdraw,
          img: require('@/assets/images/withdraw/select_vietna.png')
        },
        24: {
          component: IndiaWithdraw,
          img: require('@/assets/images/withdraw/select_vietna.png')
        },
        31: {
          component: SkrillWithdraw,
          img: require('@/assets/images/withdraw/select_skill.png')
        },
        32: {
          component: NetellerWithdraw,
          img: require('@/assets/images/channel/neteller.png')
        },
        34: {
          component: BitcoinWithdraw,
          img: require('@/assets/images/withdraw/select_bitcoin.png')
        },
        36: {
          component: UsdtWithdraw,
          img: require('@/assets/images/withdraw/select_usdt.png')
        },
        39: {
          component: IndonesiaWithdraw,
          img: require('@/assets/images/withdraw/select_vietna.png')
        },
        40: {
          component: PhilipinesWithdraw,
          img: require('@/assets/images/withdraw/select_vietna.png')
        },
        47: {
          component: LaosWithdraw,
          img: require('@/assets/images/withdraw/select_bank.png')
        },
        48: {
          component: DasshpeWithdraw,
          img: require('@/assets/images/withdraw/select_upi.png')
        },
        49: {
          component: HongKongWithdraw,
          img: require('@/assets/images/withdraw/select_vietna.png')
        },
        61: {
          component: PerfectMoneyWithdraw,
          img: require('@/assets/images/withdraw/select_PerfectMoney.png')
        },
        62: {
          component: JapanWithdraw,
          img: require('@/assets/images/withdraw/select_vietna.png')
        },
        63: {
          component: MexicoAndBrazilWithdraw,
          img: require('@/assets/images/withdraw/select_bank.png')
        },
        64: {
          component: MexicoAndBrazilWithdraw,
          img: require('@/assets/images/withdraw/select_bank.png')
        },
        65: {
          component: LocalDepositorWithdraw,
          img: require('@/assets/images/channel/deposit_LocalDepositor.png')
        },
        91: {
          component: WiseWithdraw,
          img: require('@/assets/images/channel/wise.png')
        }
      },
      chooseWithdrawalType: null,
      currentComponent: null
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.currentComponent = this.withdrawalTypesMap[event].component;
          this.chooseWithdrawalType = this.availableTypes.find(f => f.id == event);
          this.withdrawalType = event;
          this.emitWithdraw();
        }
      });
    },
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    reset(val) {
      if (!val) {
        this.emitWithdraw();
      }
    },
    availableTypes(val) {
      if (!this.availableTypes.find(f => f.id == this.withdrawalType)) {
        this.currentComponent = null;
        this.chooseWithdrawalType = null;
        this.withdrawalType = null;
        this.emitWithdraw();
      }
    },
    disabled(val) {
      if (val) {
        this.currentComponent = null;
        this.chooseWithdrawalType = null;
        this.withdrawalType = null;
        this.emitWithdraw();
      }
    }
  },
  methods: {
    emitWithdraw() {
      this.$emit('setWithdraw', {
        currentComponent: this.currentComponent,
        chooseWithdrawalType: this.chooseWithdrawalType,
        withdrawalType: this.withdrawalType
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/selectWithdraw.scss';
</style>
