<template>
  <!-- Neteller -->
  <div id="netellerForm" class="form_bottom_row line clearfix">
    <div class="fr img_row">
      <img src="@/assets/images/channel/neteller.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li>
            <InputForm
              :label="$t('withdraw.neteller.field.email')"
              type="email"
              name="netellerEmial_neteller"
              testId="netellerEmail"
              v-model="TransferForm.netellerEmial_neteller"
            ></InputForm>
          </li>
          <li>
            <ImportantNotesInput
              :label="$t('common.field.imptNotes')"
              name="importantNotes"
              v-model="TransferForm.notes_neteller"
            ></ImportantNotesInput>
          </li>
        </ul>
        <div class="alert_info">
          {{ $t('withdraw.default.NetellerChargeAlert') }}
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import { validateEmail } from '@/util/validation';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'NetellerWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  components: { ImportantNotesInput },
  mixins: [mixin],
  data() {
    return {
      TransferForm: {
        netellerEmial_neteller: '',
        notes_neteller: ''
      },
      TransferFormRules: {
        netellerEmial_neteller: [
          {
            required: true,
            message: this.$t('withdraw.neteller.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        skrillEmail: this.TransferForm.netellerEmial_neteller,
        importantNotes: this.TransferForm.notes_neteller,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>
