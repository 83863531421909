<template>
  <el-form>
    <div class="top_right">
      <el-form-item v-if="showRight">
        <div class="title">
          <span>{{ $t('withdraw.otherMethod.withdrawalMethod') }}</span>
        </div>
      </el-form-item>
      <el-form-item v-if="topForm.showCC">
        <SelectWithdraw
          name="creditCard"
          :showCreditCard="true"
          :amount="creditCardAmount"
          :reset="reset"
          :disabled="true"
        ></SelectWithdraw>
      </el-form-item>
      <el-form-item v-if="topForm.showOtherPayment">
        <SelectWithdraw
          v-model="withdrawalType"
          name="withdrawalType"
          :availableTypes="availableTypes"
          :amount="withdrawAmount"
          :reset="reset"
          :disabled="disabled"
          :selectText="getSelectText()"
          @setWithdraw="setWithdraw"
        ></SelectWithdraw>
      </el-form-item>
      <el-form-item v-if="topForm.showCC && topForm.showOtherPayment">
        <div class="withdraw_note">{{ $t('withdraw.default.withdrawNote') }}</div>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import SelectWithdraw from './SelectWithdraw';
import { apiGetNonCreditCardWithdrawTypeCP } from '@/resource';
import rounding from '@/util/rounding';

export default {
  name: 'TopRight',
  components: { SelectWithdraw },
  props: {
    withdrawForm: Object,
    topForm: Object,
    showRight: Boolean,
    reset: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      withdrawalType: null,
      availableTypes: [],
      withdrawData: {}
    };
  },
  computed: {
    creditCardAmount() {
      return this.topForm.totalCcBalance >= this.withdrawData.amount
        ? this.withdrawData.amount
        : this.topForm.totalCcBalance;
    },
    withdrawAmount() {
      return parseFloat(rounding(Math.round, this.withdrawData.amount - this.topForm.totalCcBalance, 2));
    }
  },
  watch: {
    topForm: {
      handler() {
        if (!this.reset) {
          this.fetchWithdrawalMethod();
          this.withdrawData = { ...this.withdrawForm };
        }
      },
      deep: true
    }
  },
  methods: {
    fetchWithdrawalMethod() {
      apiGetNonCreditCardWithdrawTypeCP({ accountNumber: this.withdrawForm.account.toString() })
        .then(resp => {
          if (resp.status === 200) {
            this.availableTypes =
              this.withdrawData.currency === 'USD' ? resp.data : resp.data.filter(type => ![4, 49].includes(type.id));
          } else {
            this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
          }
        })
        .catch(err => {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
        });
    },
    setWithdraw(val) {
      this.$emit('setWithdraw', val);
    },
    getSelectText() {
      return this.topForm.showCC
        ? this.$t('withdraw.default.addSecondaryMethod')
        : this.$t('withdraw.default.addWithdrawalMethod');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/topRight.scss';
</style>
