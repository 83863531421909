<template>
  <div id="withDraw">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('menu.withdrawFunds') }}</h2>
          <p></p>
        </div>
        <PendingAction
          v-if="showIdentityProofAlert"
          v-bind:type="'identityProof'"
          v-on:clickedPendingAction="pendingActionResponse"
        ></PendingAction>

        <Notification
          v-if="(pendingIDProofApproval || pendingAddressProofApproval) && !showIdentityProofAlert"
          :id="pendingIDProofApproval"
          :poa="pendingAddressProofApproval"
        ></Notification>
        <UploadIdOrAddress
          :visible.sync="uploadIdOrAddress.visible"
          :idPendingReason="idPendingReason"
          :idCustomPendingReason="idCustomPendingReason"
          :poaPendingReason="poaPendingReason"
          :poaCustomPendingReason="poaCustomPendingReason"
          :idRecode="idRecode"
          :poaRecode="poaRecode"
        ></UploadIdOrAddress>
      </div>

      <div class="inner" v-if="showWithdraw">
        <div class="withDraw_form" v-show="formFlag">
          <div class="box box_card_top">
            <TopLeft ref="topLeft" @setForm="getForm" @setTopForm="getTopForm" @reset="reset"></TopLeft>
            <div v-if="showRight">
              <hr class="vl" />
            </div>
            <TopRight
              :withdrawForm="withdrawForm"
              :topForm="topForm"
              :reset.sync="resetForm"
              :showRight="showRight"
              :disabled="disableOtherMeThod"
              @setWithdraw="getWithdraw"
            ></TopRight>
          </div>
          <div v-if="!resetForm">
            <CcMethod
              ref="ccMethod"
              v-if="topForm.showCC"
              @disableOtherMethod="onDisableOtherMethod"
              :withdrawForm="withdrawForm"
              :topForm="topForm"
              :isShowOtherPayment="topForm.showOtherPayment"
            ></CcMethod>
            <OtherMethod
              ref="otherMethod"
              v-if="(topForm.showOtherPayment && chooseWithdraw.chooseWithdrawalType) || disableOtherMeThod"
              :topForm="topForm"
              :withdrawForm="withdrawForm"
              :bankList="bankList"
              :disabled="disableOtherMeThod"
              :followerResults.sync="socialTrading.followerResultIds"
              :chooseWithdraw="chooseWithdraw"
            ></OtherMethod>
          </div>
          <div class="box box_card_submit" v-if="showSubmit">
            <el-checkbox v-model="checkStatus" data-testid="checkbox">
              <i18n path="withdraw.default.agreePolicy">
                <template v-slot:link>
                  <a @click="disclaimerVisible = true" data-testid="withdrawPolicy">
                    {{ $t('withdraw.default.withdrawPolicy') }}
                  </a>
                </template>
              </i18n>
            </el-checkbox>
            <div>
              <el-button
                class="purple_button"
                :loading="loading"
                :disabled="!checkStatus || loading"
                @click="submitForm"
                data-testid="submit"
              >
                {{ $t('withdraw.default.submitYourWithdrawal') }}
              </el-button>
            </div>
          </div>
        </div>
        <Result v-if="successFlag" :fail="result.fail">{{ result.message }}</Result>
        <vDialog :show.sync="dialog.show" :text="dialog.text">
          <template v-slot:btn>
            <div class="form_button">
              <el-button
                class="purple_button"
                v-if="dialog.method === 'amountFee'"
                @click="confirmAmountFee"
                data-testid="confirmAmountFee"
              >
                {{ $t('common.button.confirm') }}
              </el-button>
              <el-button class="white_button" @click="dialog.show = false" data-testid="cancel">
                {{ $t('common.button.cancel') }}
              </el-button>
            </div>
          </template>
        </vDialog>
        <vDialog :show.sync="positionDialog.show" :text="positionDialog.text">
          <template v-slot:btn>
            <el-button
              class="purple_button"
              v-if="positionDialog.showConfirm"
              @click="equityConfirm(submitWithdrawWrapper)"
              data-testid="confirm"
            >
              {{ $t('common.button.confirm') }}
            </el-button>
            <el-button
              class="white_button"
              v-if="positionDialog.showCancel"
              @click="hidePositionDialog"
              data-testid="cancel"
            >
              {{ $t('common.button.cancel') }}
            </el-button>
          </template>
        </vDialog>
        <disclaimerDialog :visible.sync="disclaimerVisible">
          <div class="info_box">
            <ul dir="ltr" v-html="$t('withdraw.default.disclaimer', { platform: $config.info.fullName })"></ul>
          </div>
        </disclaimerDialog>
      </div>
      <PerformanceFee
        :performanceFee="socialTrading.performanceFee"
        :dialogVisible.sync="socialTrading.dialogVisible"
        :confirmVisible.sync="socialTrading.confirmVisible"
        @confirmWithdrawClick="submitWithdraw"
      ></PerformanceFee>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import PendingAction from '@/components/home/PendingAction';
import Notification from '@/components/home/Notification';
import UploadIdOrAddress from '@/components/home/UploadIdAddress/UploadIdOrAddress';
import { apiCheckSocialTradingFollowerResult, apiApplyWithdrawal_cp_batch } from '@/resource';
import TopLeft from './TopLeft';
import TopRight from './TopRight';
import CcMethod from './CcMethod';
import OtherMethod from './OtherMethod';
import vDialog from '@/components/vDialog';
import disclaimerDialog from '@/components/home/vDialog';
import Result from '@/components/Result';
import PerformanceFee from '@/components/socialTrading/PerformanceFee';
import mixin from '@/mixins';
import idPoaMixin from '@/mixins/idPoa';
import checkPositionMixin from '@/mixins/page/tools/checkPosition';

export default {
  name: 'withdrawFunds',
  components: {
    TopLeft,
    TopRight,
    CcMethod,
    OtherMethod,
    NumericInput,
    PendingAction,
    Notification,
    UploadIdOrAddress,
    vDialog,
    disclaimerDialog,
    Result,
    PerformanceFee
  },
  mixins: [idPoaMixin, checkPositionMixin, mixin],
  data() {
    return {
      topForm: {
        creditCardBalances: [],
        showCC: false,
        showOtherPayment: false,
        totalCcBalance: 0,
        userCountryCode: ''
      },
      uploadIdOrAddress: {
        visible: false
      },
      result: {
        message: this.$t('withdraw.default.success'),
        fail: false
      },
      dialog: { show: false, text: '', method: '' },
      disclaimerVisible: false,
      withdrawForm: {
        formAccount: '',
        amount: 0,
        withdrawalType: ''
      },
      socialTrading: {
        performanceFee: 20,
        dialogVisible: false,
        confirmVisible: false,
        followerResultIds: []
      },
      chooseWithdraw: {},
      AMLAlert: { units: 100, charge: 20 },
      formFlag: true,
      successFlag: false,
      amountFeeFlag: false, //手续费
      bankList: {},
      resetForm: true,
      otherType: null,
      disableOtherMeThod: false,
      showRight: false,
      checkStatus: true,
      loading: false
    };
  },
  computed: {
    checkCountry() {
      return [4850, 6163, 5015, 6877].includes(this.topForm.userCountryCode);
    },
    showWithdraw() {
      return (
        !this.requireIDProof &&
        !this.requireAddressProof &&
        !this.pendingIDProofApproval &&
        !this.pendingAddressProofApproval
      );
    },
    showSubmit() {
      return !this.resetForm && (this.otherType || (this.topForm.showCC && !this.topForm.showOtherPayment));
    }
  },
  watch: {
    'topForm.userCountryCode'() {
      if (this.checkCountry) {
        this.AMLAlert = { units: 50, charge: 15 };
      }
    }
  },
  methods: {
    pendingActionResponse() {
      this.uploadIdOrAddress.visible = true;
    },
    getForm(form) {
      this.withdrawForm = { ...form };
    },
    getTopForm(topForm, countryBankList, showRight) {
      this.topForm = { ...topForm };
      this.bankList = countryBankList;
      this.showRight = showRight;
    },
    getWithdraw(val) {
      this.chooseWithdraw = val;
      this.otherType = val.withdrawalType;
      if (val.chooseWithdrawalType) this.$refs.topLeft.checkMinLimit(val.chooseWithdrawalType.amountLimit);
    },
    setSuccess(res) {
      this.formFlag = false;
      this.successFlag = true;
      this.handleErrorCode(res);
    },
    reset(bool) {
      this.resetForm = bool;
      this.checkStatus = true;
    },
    getMethodComponent() {
      return [this.$refs.otherMethod, this.$refs.ccMethod];
    },
    validComponent(otherMethodComponent, ccMethodComponent) {
      return [
        otherMethodComponent ? otherMethodComponent.submitForm() : Promise.resolve(),
        ccMethodComponent ? ccMethodComponent.submitForm() : Promise.resolve()
      ];
    },
    onDisableOtherMethod(bool) {
      this.disableOtherMeThod = bool;
    },
    submitForm() {
      // 父組件驗證
      console.log(this.getMethodComponent());
      const [getOtherMethodComponent, getCcMethodComponent] = this.getMethodComponent();
      const [verifyOtherMethod, verifyCcMethod] = this.validComponent(getOtherMethodComponent, getCcMethodComponent);

      Promise.all([verifyOtherMethod, verifyCcMethod]).then(valid => {
        if (valid) {
          this.checkPosition(
            this.withdrawForm.account,
            this.withdrawForm.amount,
            'withdraw',
            this.submitWithdrawWrapper
          );
        } else return false;
      });
    },
    handleErrorCode(code) {
      if (code === 300) {
        // for credit card
        this.result.fail = true;
        this.result.message = this.$t('withdraw.default.ccFail', {
          email: this.GLOBAL_CONTACT_EMAIL
        });
      }
    },
    confirmAmountFee() {
      this.dialog.show = false;
      this.checkSocialTradingFollower();
    },
    checkWithdrawlAmount() {
      if (
        this.withdrawForm.amount < (this.checkCountry ? 50 : 100) &&
        this.withdrawForm.amount !== 0 &&
        this.otherType === 4
      ) {
        this.dialog = {
          show: true,
          text: this.$t('withdraw.default.withdrawlFeeAlert', {
            units: this.AMLAlert['units'],
            charge: this.AMLAlert['charge']
          }),
          method: 'amountFee'
        };
      } else {
        this.amountFeeFlag = true;
      }
    },
    checkSocialTradingFollower() {
      this.socialTrading.followerResultIds = [];
      this.submitWithdraw();
      // apiCheckSocialTradingFollowerResult({
      //   mtAccount: this.withdrawForm.account
      // })
      //   .then(resp => {
      //     console.log(resp.data.data, resp.data);
      //     if (resp.data.code === 0 && resp.data.data) {
      //       if (resp.data.data.hasFollow) {
      //         this.socialTrading.performanceFee = resp.data.data.outstandingPerformanceFee;
      //         this.socialTrading.dialogVisible = true;
      //         this.socialTrading.followerResultIds = resp.data.data.followerResultIds;
      //       } else {
      //         this.submitWithdraw();
      //       }
      //     }
      //   })
      //   .catch(err => {
      //     this.$message({
      //       message: this.$t('withdraw.default.failed'),
      //       type: 'error'
      //     });
      //   });
    },
    submitWithdrawWrapper() {
      this.checkWithdrawlAmount();
      if (this.amountFeeFlag) {
        this.checkSocialTradingFollower();
      }
    },
    postApiApplyWithdrawal_cp() {
      const [getOtherMethodComponent, getCcMethodComponent] = this.getMethodComponent();

      let apiData = [];
      if (this.topForm.showCC) {
        const topFromData = { ...getCcMethodComponent.getFormDataOfChild() };
        if (topFromData.creditCardWithdrawalRequests.length > 0) {
          apiData.push({ ...getCcMethodComponent.getFormDataOfChild() });
        }
      }
      if (this.topForm.showOtherPayment) apiData.push({ ...getOtherMethodComponent.getFormDataOfChild() });
      return apiApplyWithdrawal_cp_batch(apiData, this.token);
    },
    submitWithdraw() {
      this.loading = true;
      this.postApiApplyWithdrawal_cp()
        .then(result => {
          if (result && result.data.code == 585) {
            this.loading = false;
            this.amountFeeFlag = false;
          } else if (result && (result.data.code == 0 || result.data.code == 300)) {
            this.loading = false;
            this.setSuccess(result.data.code);
          } else {
            this.$message({
              message: this.$t('withdraw.default.failed'),
              type: 'error'
            });
            // re-calling anti-reuse token
            this.fetchToken()
              .then(resp => {
                this.loading = false;
              })
              .catch(resp => {
                this.loading = false;
                this.$message({
                  message: this.$t('resetPassword.failed'),
                  type: 'error'
                });
              });
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message({
            message: this.$t('withdraw.default.failed'),
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
</style>
