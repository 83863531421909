<template>
  <SelectForm
    :value="value"
    @change="$emit('input', $event)"
    :disabled="disabled"
    :label="$t(label ? label : 'common.field.country_region')"
    name="country_region"
  >
    <el-option
      v-for="item in list"
      :key="item.code"
      :selected="item == value"
      :value="item.code"
      :label="$t(item.nameKey)"
      :data-testid="item.code < 10 ? 'options0' + item.code : 'options' + item.code"
    ></el-option>
  </SelectForm>
</template>

<script>
export default {
  props: {
    label: String,
    prop: String,
    value: String,
    list: Array,
    disabled: Boolean
  }
};
</script>
