<template>
  <div class="box box_card_bottom">
    <el-form>
      <el-row :gutter="40" justify="space-between" type="flex">
        <el-col :xs="24" :sm="12">
          <div class="title">
            {{ $t('withdraw.otherMethod.manageWithdrawalDetails') }}
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" class="totalAmount">{{ $t('common.field.amt') }}: {{ amount }}</el-col>
      </el-row>
      <div v-if="disabled">
        <el-alert
          :closable="false"
          :description="$t('common.withdrawChannel.pendingArchiveRequest')"
          type="warning"
          show-icon
        >
        </el-alert>
      </div>
      <component
        :is="currentComponent"
        :accountNumber="withdrawForm.account"
        :amount="amount"
        :withdrawalType="withdrawalType"
        :chooseWithdrawalType="chooseWithdrawalType"
        :userCountryCode="topForm.userCountryCode"
        :followerResults.sync="followerResults"
        :bankList="bankList"
        ref="child"
      ></component>
    </el-form>
  </div>
</template>

<script>
import rounding from '@/util/rounding';

export default {
  name: 'otherMethod',
  props: {
    withdrawForm: Object,
    topForm: Object,
    bankList: Object,
    followerResults: Array,
    chooseWithdraw: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      withdrawalType: null,
      availableTypes: [],
      currentComponent: null,
      chooseWithdrawalType: {}
    };
  },
  mounted() {
    this.setChooseWithdraw(this.chooseWithdraw);
  },
  watch: {
    chooseWithdraw(val) {
      this.setChooseWithdraw(val);
    }
  },
  computed: {
    amount() {
      return parseFloat(rounding(Math.round, this.withdrawForm.amount - this.topForm.totalCcBalance, 2));
    }
  },
  methods: {
    valideWithdrawForm() {
      this.$emit('valideWithdrawForm');
    },
    setChooseWithdraw(val) {
      this.currentComponent = val.currentComponent;
      this.chooseWithdrawalType = val.chooseWithdrawalType;
      this.withdrawalType = val.withdrawalType;
    },
    getFormOfChild() {
      // get form of child
      return this.withdrawalType === 2
        ? this.$refs.child.$refs.bankChild.$refs['TransferForm']
        : this.$refs.child.$refs['TransferForm'];
    },
    getFormDataOfChild() {
      // get data of child for apply withdrawl API
      return this.withdrawalType === 2
        ? this.$refs.child.$refs.bankChild.getFormData()
        : this.$refs.child.getFormData();
    },
    submitForm() {
      // 子組件驗證
      return this.getFormOfChild() ? this.getFormOfChild().validate() : Promise.reject();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/method.scss';
</style>
