<template>
  <div class="form_bottom_row line clearfix">
    <div class="fr img_row">
      <img src="@/assets/images/channel/wise.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li>
            <InputForm
              :label="$t('withdraw.wise.field.email')"
              type="email"
              name="email"
              testId="email"
              v-model="TransferForm.email"
            ></InputForm>
          </li>
          <li>
            <InputForm
              :label="$t('common.field.wiseAccName')"
              name="bankAccountName"
              v-model="TransferForm.bankAccountName"
            ></InputForm>
          </li>
        </ul>
        <div class="alert_info">
          <p>{{ $t('withdraw.default.wiseChargeAlert_1') }}</p>
          <p>{{ $t('withdraw.default.wiseChargeAlert_2') }}</p>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import { validateEmail } from '@/util/validation';

export default {
  name: 'NetellerWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  mixins: [mixin],
  data() {
    return {
      TransferForm: {
        email: '',
        bankAccountName: ''
      },
      TransferFormRules: {
        email: [
          {
            required: true,
            message: this.$t('withdraw.wise.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        bankAccountName: [
          {
            required: true,
            message: this.$t('withdraw.wise.formValidation.accReq'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        email: this.TransferForm.email,
        bankAccountName: this.TransferForm.bankAccountName
      };
    }
  }
};
</script>
