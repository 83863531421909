<template>
  <div class="performance_fee_dialog">
    <vDialog :visible.sync="visible" :visibleFooter="false">
      <p v-html="$t('social.trading.performance.fee.description', { performanceFee: performanceFee })"></p>
      <el-button class="purple_button" @click="confirmClick">
        {{ $t('common.button.confirm') }}
      </el-button>
      <el-button class="white_button" @click="disableDialogVisible(false)">
        {{ $t('common.button.cancel') }}
      </el-button>
    </vDialog>
  </div>
</template>

<script>
import vDialog from '@/components/home/vDialog';
export default {
  props: {
    performanceFee: Number,
    dialogVisible: Boolean,
    confirmVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableDialogVisible(bool);
    }
  },
  methods: {
    confirmClick() {
      this.disableDialogVisible(false);
      this.$emit('confirmWithdrawClick');
      this.$emit('update:confirmVisible', true);
    },
    disableDialogVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/socialTrading/performanceFee.scss';
</style>
