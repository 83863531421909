<template>
  <div>
    <p class="line title_line">{{ $t('withdraw.banxa_service') }}</p>
    <a
      class="line text_color"
      href="/static/Banxa Sell Flow Manual - Alphatick.pdf"
      download="Banxa Sell Flow Manual - Alphatick.pdf"
      >{{ $t('withdraw.guide_title') }}</a
    >
    <a class="line text_color" @click="disclaimerVisible = true" data-testid="withdrawPolicy">
      {{ $t('withdraw.disclaimer_title') }}
    </a>
    <el-dialog :visible="disclaimerVisible" top="0" @close="disclaimerVisible = false" center>
      <div slot="title">
        <img src="@/assets/images/close.png" class="closeImg" alt @click="close" />
      </div>
      <ul v-html="$t('withdraw.disclaimer')"></ul>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      disclaimerVisible: false
    };
  },
  methods: {
    close() {
      this.disclaimerVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.line {
  display: block;
  line-height: 24px;
}
.text_color {
  color: #055bff;
}
.closeImg {
  width: 22px;
  position: absolute;
  top: 4%;
  right: 2%;
  cursor: pointer;
}
.title_line {
  color: #38438f;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: 'GTWalsheimPro-Bold';
}
</style>
