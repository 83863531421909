<template>
  <div id="usdtWithdraw" class="form_bottom_row line clearfix">
    <div class="fr img_row">
      <img class="small" src="@/assets/images/channel/crypto.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list_three_columns clearfix">
          <li>
            <SelectForm v-model="TransferForm.chain" :label="$t('withdraw.usdt.field.chain')" name="chain">
              <el-option
                v-for="option in formChainOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              ></el-option>
            </SelectForm>
          </li>
          <li>
            <InputForm
              :label="$t('withdraw.usdt.field.address')"
              name="address"
              maxlength="64"
              v-model="TransferForm.address"
              testId="cryptoWalletAddress"
            ></InputForm>
          </li>
          <li class="verification-code">
            <InputForm
              class="input_code"
              :label="$t('common.field.enterVerificationCode')"
              name="code"
              type="code"
              testId="code"
              v-model="TransferForm.code"
            ></InputForm>
            <el-button
              class="el-button purple_button"
              :disabled="isSendEmail"
              @click="sendEmail()"
              data-testid="code-button"
              >{{ isClickSendEmail ? $t('common.button.resendCode') : $t('common.button.sendCode') }}
              {{ displayTime > 0 ? `(${displayTime})` : '' }}</el-button
            >
          </li>
          <li>
            <ImportantNotesInput
              :label="$t('common.field.imptNotes')"
              name="importantNotes"
              v-model="TransferForm.notes"
            ></ImportantNotesInput>
          </li>
        </ul>
        <div class="err-code" v-if="errorCode !== 0">
          {{ $t(`responseMsg.${errorCode}`) }}
        </div>
        <div class="remind-email" v-if="isClickSendEmail">
          {{ $t('withdraw.otherMethod.verificationCode', { email: getEmail, supportEmail: GLOBAL_CONTACT_EMAIL }) }}
        </div>
        <div class="alert_info">{{ $t(`deposit.crypto.note`) }}</div>
        <div v-if="showUSDTNote" class="alert_info">{{ $t(`deposit.crypto.usdt.note`) }}</div>
      </el-form>
      <disclaimer-dialog></disclaimer-dialog>
    </div>
  </div>
</template>
<script>
import mixin from '@/mixins';
import disclaimerDialog from '@/components/home/disclaimerDialog';
import { apiSendEmailVerificationCode } from '@/resource';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  components: { disclaimerDialog, ImportantNotesInput },
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  mixins: [mixin],
  data() {
    return {
      isSendEmail: false,
      isClickSendEmail: false,
      secondTime: 60,
      displayTime: 0,
      timeInterval: null,
      errorCode: 0,
      txId: '',
      namespace: 'CP_WITHDRAW',
      formChainOptions: [
        { text: 'TRC20', value: 'trc20' },
        { text: 'ERC20', value: 'erc20' }
      ],
      TransferForm: {
        address: '',
        notes: '',
        chain: 'trc20',
        code: ''
      },
      TransferFormRules: {
        address: [
          {
            required: true,
            message: this.$t('withdraw.usdt.formValidation.addressReq'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              const chainType = this.TransferForm.chain;

              if (chainType === 'trc20' && !/^T/.test(value)) {
                callback(new Error(this.$t('withdraw.usdt.formValidation.walletFormatIncorrect', { type: 'TRC20' })));
              } else if (chainType === 'erc20' && !/^0x/.test(value)) {
                callback(new Error(this.$t('withdraw.usdt.formValidation.walletFormatIncorrect', { type: 'ERC20' })));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        chain: [
          {
            required: true,
            message: this.$t('withdraw.usdt.formValidation.chain'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              this.$refs.TransferForm.validateField('address');
              callback();
            },
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: this.$t('common.formValidation.verCodeReq'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    showUSDTNote() {
      return this.TransferForm.chain === 'erc20';
    },
    getEmail() {
      return this.$store.state.common.emailMasked;
    }
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        cryptoWalletAddress: this.TransferForm.address,
        importantNotes: this.TransferForm.notes,
        followerResultIds: this.followerResults,
        txId: this.txId,
        code: this.TransferForm.code
      };
    },
    sendEmail() {
      apiSendEmailVerificationCode({ namespace: this.namespace }).then(resp => {
        this.isSendEmail = true;
        this.isClickSendEmail = true;
        this.startTimer();
        if (resp.data.code === 0) this.txId = resp.data.data.txId;
        else this.errorCode = resp.data.code;
      });
    },
    startTimer() {
      this.displayTime = this.secondTime;
      this.timeInterval = setInterval(this.countDown, 1000);
    },
    countDown() {
      this.displayTime--;
      if (this.displayTime === 0) this.stopTimer();
    },
    stopTimer() {
      clearInterval(this.timeInterval);
      this.isSendEmail = false;
    },
    getFormDataOfChild() {
      return {
        txId: this.txId,
        code: this.TransferForm.code
      };
    },
    submitForm() {
      return this.$refs['TransferForm'].validate();
    }
  }
};
</script>
<style lang="scss" scoped>
.verification-code {
  display: flex;
  .input_code {
    flex: 1;
  }
}
</style>
