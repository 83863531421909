<template>
  <div id="hongkongForm" class="form_bottom_row line clearfix">
    <div class="fr img_row" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
      <img src="@/assets/images/channel/vietna.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="numberSelection"
              testId="selectedCardID"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form_list clearfix">
            <li>
              <SelectForm
                :label="$t('common.field.bankCode')"
                name="bankName"
                v-model="TransferForm.bankName"
                :disabled="isdisabled"
                @change="handleBankCodeChange"
              >
                <el-option v-for="bank in banks" :key="bank.bankCode" :value="bank.value"></el-option>
              </SelectForm>
            </li>
            <li>
              <SelectForm
                :label="$t('common.field.bankBranchCode')"
                name="bankBranchName"
                v-model="TransferForm.bankBranchName"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="bankBranch in bankBranches"
                  :key="bankBranch.branchCode"
                  :value="bankBranch.value"
                ></el-option>
              </SelectForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankAccName')"
                name="bankAccName"
                v-model="TransferForm.bankAccName"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankAccNum')"
                name="accountNumber"
                v-model.trim="TransferForm.accountNumber"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <SelectForm
                v-model="TransferForm.withdrawCurrency"
                :label="$t('common.field.bankAccountCurrency')"
                name="withdrawCurrency"
              >
                <el-option
                  v-for="currency in supportedCurrencies"
                  :key="currency"
                  :value="currency"
                  :label="currency"
                  :data-testid="currency"
                ></el-option>
              </SelectForm>
              <div class="rate" v-if="isHKD">
                {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'HKD' }) }}
                <span>{{ rate }}</span>
              </div>
            </li>
            <li>
              <ImportantNotesInput
                :label="$t('common.field.imptNotes')"
                name="importantNotes"
                v-model="TransferForm.importantNotes"
              ></ImportantNotesInput>
            </li>
          </ul>
          <ul class="form_list clearfix" v-if="!isdisabled">
            <li>
              <el-form-item class="upload" prop="uploadedFile">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('withdraw.transfer.label.upload') }}</label>
                </div>
                <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFile"></vUpload>
              </el-form-item>
            </li>
            <li>
              <p class="int_p" v-html="$t('withdraw.transfer.info', { platform: $config.info.fullName })"></p>
            </li>
          </ul>
          <div class="checkbox_wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
              {{ $t('withdraw.default.remember') }}</el-checkbox
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  apiWithdrawalQueryRateUSDByTargetCurrency,
  apiGetWithdrawalBankCode,
  apiGetWithdrawalBankBranchCode
} from '@/resource';
import mixin from '@/mixins';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'hongkongWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    followerResults: Array,
    bankList: Object,
    currentCurrency: String
  },
  components: { vUpload, ImportantNotesInput },
  mixins: [mixin, bankTransferMixin, withdrawlMixin],
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      TransferForm: {
        bankAccName: '',
        accountNumber: '',
        importantNotes: '',
        withdrawCurrency: '',
        userPaymentInfoId: null,
        bankName: '',
        bankBranchName: ''
      },
      banks: [],
      bankBranches: [],
      isHKD: false,
      supportedCurrencies: ['HKD', 'USD'],
      rate: 7.75,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankCodeReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchCodeReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAccName: [
          {
            required: true,
            message: this.$t('common.formValidation.accNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        withdrawCurrency: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.bankAccountCurrency')
            }),
            trigger: ['blur', 'change']
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      fileList: []
    };
  },
  methods: {
    queryRate() {
      apiWithdrawalQueryRateUSDByTargetCurrency('HKD')
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'HKD' })
          );
        });
    },
    queryBankCode() {
      apiGetWithdrawalBankCode()
        .then(resp => {
          if (resp.data.code == 0)
            this.banks = resp.data.data.map(bank => ({ ...bank, value: bank.bankCode + ' - ' + bank.bankName }));
        })
        .catch(err => {
          this.errorMessage(this.$t('Get Bank Code Error.'));
        });
    },
    queryBankBranchCode(bankCode) {
      apiGetWithdrawalBankBranchCode(bankCode)
        .then(resp => {
          if (resp.data.code == 0)
            this.bankBranches = resp.data.data.map(bank => ({
              ...bank,
              value: bank.branchCode + ' - ' + bank.branchName
            }));
        })
        .catch(err => {
          this.errorMessage(this.$t('Get Bank Branch Code Error.'));
        });
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['TransferForm'].validateField('uploadedFile');
    },
    setCurrency(currency) {
      this.TransferForm.withdrawCurrency = currency;
      if (currency === 'HKD') this.isHKD = true;
      else this.isHKD = false;
    },
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankBranchName: this.TransferForm.bankBranchName,
        bankName: this.TransferForm.bankName,
        bankAccountName: this.TransferForm.bankAccName,
        accountNumber: this.TransferForm.accountNumber,
        importantNotes: this.TransferForm.importantNotes,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        fileList: this.fileList,
        followerResultIds: this.followerResults,
        targetCurrency: this.TransferForm.withdrawCurrency
      };
    },
    handleBankCodeChange(value) {
      let currentBankCode = this.banks.find(bank => bank.value === value).bankCode;
      this.queryBankBranchCode(currentBankCode);
      this.TransferForm.bankBranchName = '';
    }
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.TransferForm = {
        selectedCardID: value,
        bankAccName: selectedCardInfo.bankAccountName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null,
        targetCurrency: selectedCardInfo.withdrawCurrency || '',
        bankName: selectedCardInfo.bankName || '',
        bankBranchName: selectedCardInfo.bankBranchName || ''
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    },
    'TransferForm.withdrawCurrency'(value) {
      if (value === 'HKD') this.isHKD = true;
      else this.isHKD = false;
    }
  },

  mounted() {
    this.queryRate();
    this.queryBankCode();
  }
};
</script>
