<template>
  <div id="DasshpeForm" class="form_bottom_row line clearfix">
    <div class="fr img_row">
      <img src="@/assets/images/channel/deposit_upi.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li>
            <InputForm :label="$t('common.field.vpa')" name="vpa" v-model="TransferForm.vpa"></InputForm>
          </li>
          <li>
            <InputForm :label="$t('common.field.name')" name="name" v-model="TransferForm.name"></InputForm>
          </li>
          <li>
            <InputForm
              :label="$t('common.field.email')"
              name="email"
              type="email"
              v-model="TransferForm.email"
            ></InputForm>
          </li>
          <li>
            <InputForm :label="$t('common.field.phone')" name="phone" v-model="TransferForm.phone"></InputForm>
          </li>
          <li>
            <InputForm :label="$t('common.field.address')" name="address" v-model="TransferForm.address"></InputForm>
          </li>
          <li>
            <ImportantNotesInput
              :label="$t('common.field.imptNotes')"
              name="importantNotes"
              v-model="TransferForm.importantNotes"
            ></ImportantNotesInput>
          </li>
        </ul>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import { validateEmail } from '@/util/validation';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'DasshpeWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  components: { ImportantNotesInput },
  mixins: [mixin],
  data() {
    return {
      TransferForm: {
        vpa: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        importantNotes: ''
      },
      TransferFormRules: {
        email: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        vpa: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.vpa') }),
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.name') }),
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.phone') }),
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.address') }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        vpa: this.TransferForm.vpa,
        upiName: this.TransferForm.name,
        upiEmail: this.TransferForm.email,
        upiPhoneNo: this.TransferForm.phone,
        upiAddress: this.TransferForm.address,
        importantNotes: this.TransferForm.importantNotes,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>
