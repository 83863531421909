<template>
  <!-- Perfect Money -->
  <div id="perfectMoneyForm" class="form_bottom_row line clearfix">
    <div class="fr img_row small">
      <img class="small" src="@/assets/images/channel/deposit_PerfectMoney.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li>
            <InputForm
              :label="$t('withdraw.perfectMoney.field.email')"
              name="perfectMoney_email"
              type="email"
              v-model="TransferForm.perfectMoney_email"
            ></InputForm>
          </li>
          <li>
            <ImportantNotesInput :label="$t('common.field.imptNotes')" name="notes" v-model="TransferForm.notes"></ImportantNotesInput>
          </li>
        </ul>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import { validateEmail } from '@/util/validation';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'perfectMoneyWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  components: { ImportantNotesInput },
  mixins: [mixin],
  data() {
    return {
      TransferForm: {
        perfectMoney_email: '',
        notes: ''
      },
      TransferFormRules: {
        perfectMoney_email: [
          {
            required: true,
            message: this.$t('withdraw.perfectMoney.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        perfectMoneyEmail: this.TransferForm.perfectMoney_email,
        importantNotes: this.TransferForm.notes,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>
