<template>
  <div id="IndiaForm" class="form_bottom_row line clearfix">
    <div class="fr img_row" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
      <img src="@/assets/images/channel/vietna.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="numberSelection"
              testId="selectedCardID"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form_list clearfix">
            <li>
              <InputForm
                :label="$t('common.field.bankName')"
                name="bankName"
                v-model="TransferForm.bankName"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankAddress')"
                name="bankAddress"
                v-model="TransferForm.bankAddress"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankBeneficiaryName')"
                name="bankBeneficiaryName"
                v-model="TransferForm.bankBeneficiaryName"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankAccNum')"
                name="accountNumber"
                v-model.trim="TransferForm.accountNumber"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankBranch')"
                name="bankBranch"
                v-model="TransferForm.bankBranch"
              ></InputForm>
            </li>
            <li>
              <InputForm :label="$t('common.field.ifscCode')" name="ifsc" v-model="TransferForm.ifsc"></InputForm>
            </li>
            <li>
              <InputForm :label="$t('common.field.email')" name="email" v-model="TransferForm.email"></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.phone')"
                name="phoneNumber"
                v-model="TransferForm.phoneNumber"
              ></InputForm>
            </li>
            <li>
              <ImportantNotesInput
                :label="$t('common.field.imptNotes')"
                name="importantNotes"
                v-model="TransferForm.notes"
              ></ImportantNotesInput>
            </li>
          </ul>
          <div class="checkbox_wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
              {{ $t('withdraw.default.remember') }}</el-checkbox
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { validateEmail } from '@/util/validation';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'IndiaWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    followerResults: Array,
    bankList: Object
  },
  components: { ImportantNotesInput },
  mixins: [mixin, bankTransferMixin, withdrawlMixin],
  data() {
    return {
      TransferForm: {
        bankName: '',
        bankAddress: '',
        Swift: '',
        bankBeneficiaryName: '',
        accountNumber: '',
        bankBranch: '',
        ifsc: '',
        email: '',
        phoneNumber: '',
        notes: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),

            trigger: 'blur'
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: 'blur'
          }
        ],
        Swift: [
          {
            required: true,
            message: this.$t('common.formValidation.swiftReq'),
            trigger: 'blur'
          }
        ],
        bankBeneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        bankBranch: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.bankBranch') }),
            trigger: 'blur'
          }
        ],
        ifsc: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.ifscCode') }),
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.phone') }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName,
        bankAddress: this.TransferForm.bankAddress,
        beneficiaryName: this.TransferForm.bankBeneficiaryName,
        bankBranchName: this.TransferForm.bankBranch,
        ifscCode: this.TransferForm.ifsc,
        email: this.TransferForm.email,
        phoneNumber: this.TransferForm.phoneNumber,
        accountNumber: this.TransferForm.accountNumber,
        importantNotes: this.TransferForm.notes,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    }
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      console.log(selectedCardInfo);
      this.TransferForm = {
        selectedCardID: value,
        bankName: isCurrentCard ? selectedCardInfo.bankName : '',
        bankAddress: isCurrentCard ? selectedCardInfo.bankAddress : '',
        bankBeneficiaryName: isCurrentCard ? selectedCardInfo.beneficiaryName : '',
        bankBranch: isCurrentCard ? selectedCardInfo.bankBranchName : '',
        ifsc: isCurrentCard ? selectedCardInfo.ifscCode : '',
        email: isCurrentCard ? selectedCardInfo.email : '',
        phoneNumber: isCurrentCard ? selectedCardInfo.phoneNumber : '',
        accountNumber: isCurrentCard ? selectedCardInfo.accountNumber : '',
        notes: isCurrentCard ? selectedCardInfo.importantNotes : '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    },
    'TransferForm.bankBeneficiaryName'(value) {
      this.TransferForm.bankBeneficiaryName = this.latinNumberValidator(value);
    }
  }
};
</script>
