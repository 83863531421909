<template>
  <!-- FasaPay -->
  <div id="fasapayForm" class="form_bottom_row line clearfix">
    <div class="fr img_row">
      <img src="@/assets/images/channel/fasapay.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li>
            <InputForm
              :label="$t('withdraw.fasa.field.accName')"
              name="fasapayAccountName_fasapay"
              v-model="TransferForm.fasapayAccountName_fasapay"
              testId="accountName"
            ></InputForm>
          </li>
          <li>
            <InputForm
              :label="$t('withdraw.fasa.field.accNum')"
              name="fasapayAccountNumber_fasapay"
              v-model="TransferForm.fasapayAccountNumber_fasapay"
              testId="accountNumber"
            ></InputForm>
          </li>
        </ul>
        <ul class="form_list clearfix">
          <li>
            <ImportantNotesInput
              :label="$t('common.field.imptNotes')"
              name="importantNotes"
              v-model="TransferForm.notes_fasapay"
              testId="importantNotes"
            ></ImportantNotesInput>
          </li>
        </ul>
        <div class="alert_info">
          {{ $t('withdraw.default.FasaPayChargeAlert') }}
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'FasapayWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  components: { ImportantNotesInput },
  mixins: [mixin],
  data() {
    return {
      TransferForm: {
        fasapayAccountName_fasapay: '',
        fasapayAccountNumber_fasapay: '',
        notes_fasapay: ''
      },
      TransferFormRules: {
        fasapayAccountName_fasapay: [
          {
            required: true,
            message: this.$t('withdraw.fasa.formValidation.accNameReq'),
            trigger: 'blur'
          }
        ],
        fasapayAccountNumber_fasapay: [
          {
            required: true,
            message: this.$t('withdraw.fasa.formValidation.accNumReq'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        accountName: this.TransferForm.fasapayAccountName_fasapay,
        accountNumber: this.TransferForm.fasapayAccountNumber_fasapay,
        importantNotes: this.TransferForm.notes_fasapay,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>
