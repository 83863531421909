<template>
  <el-form
    :model="withdrawForm"
    ref="withdrawForm"
    :rules="withdrawFormRules"
    class="top_left"
    :class="{ 'left-style': showRight }"
  >
    <el-form-item>
      <div class="title">
        <span>{{ $t('withdraw.default.accountDetail') }}</span>
      </div>
    </el-form-item>
    <el-form-item class="labelTitle">
      <div>
        <label for="">{{ $t('withdraw.default.tradingPlatform') }}</label>
      </div>
      <div class="row">
        {{ mtAccountType }}
      </div>
    </el-form-item>
    <div class="accSelect">
      <SelectForm
        v-model="withdrawForm.formAccount"
        :label="$t('withdraw.default.accountSelect')"
        name="formAccount"
        class="showPlaceholder"
      >
        <el-option
          v-for="item in formAccountOptions"
          :key="item.login"
          :value="JSON.stringify(item)"
          :label="getCurrencySymbol(item)"
          :data-testid="item.login"
        ></el-option>
      </SelectForm>
    </div>

    <div class="amount">
      <numeric-input
        ref="numberInput"
        v-model="withdrawForm.amount"
        :currency="withdrawForm.currency"
        :precision="2"
        :label="$t('withdraw.default.withdrawalAmount')"
        name="amount"
      ></numeric-input>
    </div>
    <div class="form_button">
      <el-button class="purple_button" @click="submit" data-testid="submit"
        >{{ $t('common.button.continue') }} <i class="el-icon-arrow-right"></i
      ></el-button>
    </div>
  </el-form>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import splitThousands from '@/util/splitThousands';
import { apiGetWithdrawalData_cp, apiCreditCardBalances } from '@/resource';

export default {
  name: 'TopLeft',
  components: { NumericInput },
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) <= 0) {
        callback(new Error(this.$t('common.formValidation.amt0')));
      } else if (value < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger_currency_type', {
              minLimit: this.minLimit
            }) +
              ' ' +
              this.withdrawForm.currency
          )
        );
      } else if (value > this.withdrawForm.balance) {
        callback(new Error(this.$t('withdraw.default.YourPaymentGreaterThanAvailableBalance')));
      } else callback();
    };
    return {
      creditCardBalances: [],
      formAccountOptions: [],
      countryBankList: [],
      userCountryCode: null,
      mtAccountType: null,
      withdrawForm: {
        formAccount: '',
        account: '',
        amount: 0,
        mtCategory: '',
        currency: null,
        balance: null
      },
      methodMinAmt: 0,
      disabled: false,
      withdrawFormRules: {
        formAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      topForm: {
        showOtherPayment: false
      },
      reset: true,
      showRight: false
    };
  },
  mounted() {
    this.fetchTradingAccount();
    this.$root.$refs.topLeft = this;
  },
  computed: {
    minLimit() {
      if (this.withdrawForm.currency === 'JPY') return 7000;
      else return this.methodMinAmt > 0 ? this.methodMinAmt : 30;
    },
    showOtherPayment() {
      return this.withdrawForm.amount > this.totalCcBalance || this.creditCardBalances.length <= 0;
    },
    totalCcBalance() {
      let total = 0;
      this.creditCardBalances.forEach(cc => {
        if (cc.creditCard.is_del !== 1 && !cc.isExpired && !cc.isPending) {
          total = total + cc.balance;
        }
      });
      return total;
    }
  },
  watch: {
    withdrawForm: {
      handler(form) {
        this.$emit('setForm', form);
        this.$emit('reset', true);
        this.reset = true;
      },
      deep: true
    },
    'withdrawForm.formAccount'(value) {
      const jsonParse = JSON.parse(value);
      this.withdrawForm.account = jsonParse.login;
      this.withdrawForm.balance = jsonParse.balance;
      this.withdrawForm.mtCategory = jsonParse.mtCategory;
      if (this.withdrawForm.currency === jsonParse.currency) {
        this.$refs.numberInput.updateCurrency(true);
      }
      this.withdrawForm.currency = jsonParse.currency;

      this.methodMinAmt = 0;
      this.$refs['withdrawForm'].fields.find(f => f.prop == 'amount').resetField();

      this.mtAccountType = jsonParse.mtAccountTypeDisplay;

      this.disabled = false;
    },
    'withdrawForm.amount'(value) {
      this.methodMinAmt = 0;
      this.disabled = false;
    },
    formAccountOptions(options) {
      if (options.length > 0) {
        this.withdrawForm.formAccount = JSON.stringify(options[0]);
      }
    }
  },
  methods: {
    fetchTradingAccount() {
      apiGetWithdrawalData_cp()
        .then(resp => {
          if (resp.data.code === 0) {
            this.userCountryCode = parseInt(resp.data.data.countryCode);
            this.formAccountOptions = resp.data.data.logins;
            this.countryBankList = resp.data.data.withdrawBankList;
          }
        })
        .catch(err => {
          this.$message({
            message: this.$t('withdraw.default.fetchTradeAccFailed'),
            type: 'error'
          });
        });
    },
    submit() {
      this.$refs['withdrawForm'].validate(valid => {
        if (valid) {
          this.getApiCreditCardBalances();
        } else return false;
      });
    },
    checkMinLimit(amount) {
      this.methodMinAmt = amount;

      this.$refs['withdrawForm'].validate();
    },
    getApiCreditCardBalances() {
      apiCreditCardBalances({ currency: this.withdrawForm.currency })
        .then(resp => {
          this.creditCardBalances = resp.data;
          this.showRight = true;
          this.emitTopForm(this.creditCardBalances);
          this.$emit('reset', false);
        })
        .catch(err => {
          console.log(err);
        });
    },
    emitTopForm(creditCardBalances) {
      let showCC = creditCardBalances.length > 0 ? true : false;
      this.topForm = {
        creditCardBalances: creditCardBalances,
        showCC: showCC,
        showOtherPayment: this.showOtherPayment,
        totalCcBalance: this.totalCcBalance,
        userCountryCode: this.userCountryCode
      };
      this.$emit('setTopForm', this.topForm, this.countryBankList, this.showRight);
      this.disabled = showCC || this.showOtherPayment;
    },
    getCurrencySymbol(item) {
      function getAlins(item) {
        let alias = {
          'Raw ECN': ' Alpha ECN'
        };
        if (item.mtAccountTypeDisplay == 'MT4' && item.accountDisplayType in alias) {
          return alias[item.accountDisplayType];
        }
        return item.accountDisplayType;
      }
      return (
        item.login +
        ' - ' +
        getAlins(item) +
        ' - ' +
        this.$options.filters.currencyToSymbol(item.currency) +
        splitThousands(item.balance, 2) +
        ' ' +
        item.currency
      );
    },
    resetWithdrawalData() {
      this.$emit('reset', true);
      this.getApiCreditCardBalances();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/topLeft.scss';
</style>
