<template>
  <div id="MexicoAndBrazilForm" class="form_bottom_row line clearfix">
    <div class="fr img_row" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
      <img src="@/assets/images/withdraw/bank_logo.png" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="numberSelection"
              testId="selectedCardID"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form_list clearfix">
            <li>
              <SelectForm
                v-model="TransferForm.bankName"
                name="bankName"
                :label="$t('common.field.bankName')"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="(value, name) in bankList"
                  :key="name"
                  :value="value"
                  :label="name"
                  :data-testid="name"
                ></el-option>
              </SelectForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankBeneficiaryName')"
                name="bankBeneficiaryName"
                v-model="TransferForm.bankBeneficiaryName"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankAccNum')"
                name="accountNumber"
                v-model.trim="TransferForm.accountNumber"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankProvince')"
                name="bankProvince"
                v-model="TransferForm.bankProvince"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankCity')"
                name="bankCity"
                v-model="TransferForm.bankCity"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <ImportantNotesInput
                :label="$t('common.field.imptNotes')"
                name="importantNotes"
                v-model="TransferForm.notes"
              ></ImportantNotesInput>
            </li>
          </ul>
          <ul class="form_list clearfix" v-if="!isdisabled">
            <li>
              <el-form-item class="upload" prop="uploadedFile">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('withdraw.transfer.label.upload') }}</label>
                </div>
                <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFile"></vUpload>
              </el-form-item>
            </li>
          </ul>
          <div class="checkbox_wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
              {{ $t('withdraw.default.remember') }}</el-checkbox
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'MexicoAndBrazilWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    followerResults: Array,
    bankList: Object
  },
  mixins: [mixin, bankTransferMixin, withdrawlMixin],
  components: { vUpload, ImportantNotesInput },
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      TransferForm: {
        bankName: '',
        bankBeneficiaryName: '',
        accountNumber: '',
        bankProvince: '',
        bankCity: '',
        notes: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'blur'
          }
        ],
        bankBeneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        bankProvince: [
          {
            required: true,
            message: this.$t('common.formValidation.bankProvinceReq'),
            trigger: 'blur'
          }
        ],
        bankCity: [
          {
            required: true,
            message: this.$t('common.formValidation.bankCityReq'),
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      fileList: []
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName,
        beneficiaryName: this.TransferForm.bankBeneficiaryName,
        accountNumber: this.TransferForm.accountNumber,
        bankProvince: this.TransferForm.bankProvince,
        bankCity: this.TransferForm.bankCity,
        fileList: this.fileList,
        importantNotes: this.TransferForm.notes,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['TransferForm'].validateField('uploadedFile');
    }
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;

      this.TransferForm = {
        selectedCardID: value,
        bankName: isCurrentCard ? selectedCardInfo.bankName : '',
        bankBeneficiaryName: isCurrentCard ? selectedCardInfo.beneficiaryName : '',
        accountNumber: isCurrentCard ? selectedCardInfo.accountNumber : '',
        bankProvince: isCurrentCard ? selectedCardInfo.bankProvince : '',
        bankCity: isCurrentCard ? selectedCardInfo.bankCity : '',
        notes: isCurrentCard ? selectedCardInfo.importantNotes : '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    },
    'TransferForm.bankBeneficiaryName'(value) {
      this.TransferForm.bankBeneficiaryName = this.latinNumberValidator(value);
    }
  }
};
</script>
