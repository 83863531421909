<template>
  <div id="visaForm" class="form_bottom_row line clearfix">
    <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
      <el-row :gutter="10" justify="space-between" type="flex">
        <el-col :xs="24" :md="15" :lg="12">
          <ul class="form_list clearfix">
            <li>
              <InputForm
                :label="$t('common.field.nameOnCard')"
                name="card_holder_name"
                v-model="TransferForm.card_holder_name"
                :disabled="true"
              ></InputForm>
            </li>
            <li class="cc">
              <el-row :gutter="30">
                <el-col :xs="24" :sm="16" :md="13">
                  <el-form-item required>
                    <div class="labelTitle">
                      <span class="required_icon">*</span>
                      <label for="">{{ $t('common.field.ccNum') }}</label>
                    </div>
                    <div class="row">
                      <InputForm v-model="creditCardNumber" name="digitsCard_visa" :disabled="true"></InputForm>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="11">
                  <div class="expDate">
                    <div class="labelTitle">
                      <span class="required_icon">*</span>
                      <label for=""> {{ $t('common.field.expDate') }}</label>
                    </div>
                    <div class="row">
                      <el-form-item prop="expiry_month" class="month fl" required>
                        <Month
                          :year="TransferForm.expiry_year"
                          :disabled="disabledCardExpiry"
                          v-model="TransferForm.expiry_month"
                          @input="onCcDateChange()"
                        ></Month>
                      </el-form-item>
                      <el-form-item prop="expiry_year" class="year fl" required>
                        <Year
                          v-model="TransferForm.expiry_year"
                          :disabled="disabledCardExpiry"
                          @input="onCcDateChange()"
                        ></Year>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </li>
          </ul>
        </el-col>
        <el-col :xs="18" :sm="18" :md="6">
          <div class="fr img_row">
            <img src="@/assets/images/withdraw/credit_img.jpg" alt class="img2" /><br />
            <div class="card_sample" v-html="txtcard_visa"></div>
          </div>
        </el-col>
        <el-col :span="24" v-if="isExpired && archivable">
          <span class="red">{{ $t('common.withdrawChannel.updateExpiryMsg') }}</span>
        </el-col>
        <el-col :xs="24" v-if="ccDetail.availableCard">
          <ImportantNotesInput
            :label="$t('common.field.imptNotes')"
            name="notes_visa"
            v-model="TransferForm.importantNotes"
          ></ImportantNotesInput>
        </el-col>
        <el-col :xs="24" v-if="ccDetail.availableCard">
          <div>
            {{ $t('paymentDetails.cc.field.withrawlable', { amount: withdrawable }) }}
          </div>
        </el-col>
        <el-col :span="24" v-if="archivable">
          <span class="red">{{ $t('common.withdrawChannel.archiveThisCard') }}</span>
        </el-col>
        <el-col :xs="24">
          <div class="archive-action">
            <el-button
              class="purple_button"
              v-if="(isExpired && archivable) || ccDetail.availableCard"
              @click="confirmPrompt('update')"
              :disabled="expCardUpdateDisabled"
            >
              {{ $t('common.withdrawChannel.update') }}
            </el-button>
            <el-button class="white_button" @click="showArchiveForm = true" v-if="!showArchiveForm && archivable">
              {{ $t('common.withdrawChannel.archive') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div class="doc_upload" v-if="showArchiveForm">
      <div class="required-document">{{ $t('common.withdrawChannel.requiredDocument') }}</div>
      <div class="upload-tip">
        <span>{{ $t('fileUploader.supportedFormatCustom', { files: 'JPEG/JPG, PNG, PDF' }) }}, </span>
        <span>{{ $t('fileUploader.maxSize', { maxSize: '5' }) }}, </span>
        <span>{{ $t('fileUploader.maxFileNum', { maxFileNum: '1' }) }}</span>
      </div>
      <el-form :model="archiveForm" :rules="archiveFormRules" ref="archiveForm">
        <ul class="archiveForm" v-if="!isdisabled">
          <li>
            <el-form-item class="upload" prop="cardBankLetterPath">
              <div class="labelTitle">
                <span class="required_icon">*</span>
                <label for="">{{ $t('common.withdrawChannel.officialLetter') }}</label>
              </div>
              <vUpload
                :limit="uploadLimit"
                :selfText="true"
                :selfTip="true"
                :allowedFile="['png', 'jpg', 'jpeg', 'pdf']"
                v-on:updateFileInfo="fileList => updateFileInfo(fileList, 'cardBankLetterPath')"
              >
                <div class="upload-btn">
                  <el-button class="purple_button" type="primary" data-testid="uploadBankLetter">
                    {{ $t('common.field.upload') }}
                  </el-button>
                </div>
              </vUpload>
            </el-form-item>
          </li>
          <li>
            <el-form-item class="upload" prop="cardStatementPath">
              <div class="labelTitle">
                <span class="red">* </span>
                <label for="">{{ $t('common.withdrawChannel.bankStatement') }}</label>
              </div>
              <vUpload
                :limit="uploadLimit"
                :selfText="true"
                :selfTip="true"
                :allowedFile="['png', 'jpg', 'jpeg', 'pdf']"
                v-on:updateFileInfo="fileList => updateFileInfo(fileList, 'cardStatementPath')"
              >
                <div class="upload-btn">
                  <el-button class="purple_button" type="primary" data-testid="uploadBankStatement">
                    {{ $t('common.field.upload') }}
                  </el-button>
                </div>
              </vUpload>
            </el-form-item>
          </li>
        </ul>
      </el-form>
      <div class="archive-action">
        <el-button class="purple_button" @click="onArchiveSubmit" data-testid="archiveSubmit">
          {{ $t('common.button.submit') }}
        </el-button>
        <el-button class="white_button" plain @click="onArchiveCancel" data-testid="cancel">
          {{ $t('common.button.cancel') }}
        </el-button>
      </div>
    </div>
    <vDialog :show.sync="dialog.show" :text="dialog.text">
      <template v-slot:btn>
        <div class="form_button">
          <el-button class="purple_button" v-if="archiveStatus" @click="archiveSubmit" data-testid="dialogSubmit">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button class="purple_button" v-else @click="onExpiredCardUpdate" data-testid="dialogSubmit">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button class="white_button" @click="dialog.show = false" data-testid="dialogCancel">
            {{ $t('common.button.cancel') }}
          </el-button>
        </div></template
      >
    </vDialog>
  </div>
</template>

<script>
import { cardAuditSubmit, apiUpdateCreditCard } from '@/resource';
import mixin from '@/mixins';
import { valiCreditNumberFirst, valiCreditNumberLast } from '@/util/validation';
import vDialog from '@/components/vDialog';
import vUpload from '@/components/vUpload';
import Month from '@/components/form/Month';
import Year from '@/components/form/Year';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'VisaWithdraw',
  props: {
    ccDetail: Object,
    creditCardNumber: String,
    isExpired: {
      type: Boolean,
      default: false
    },
    archivable: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixin],
  components: { vDialog, vUpload, Month, Year, ImportantNotesInput },
  data() {
    const checkUploadFile = (rule, value, callback, name) => {
      if (!this.archiveForm[name]) {
        callback(new Error(this.$t('common.formValidation.file')));
      }
      callback();
    };
    const getUploadRules = name => {
      return [
        {
          validator: function (rule, value, callback) {
            checkUploadFile(rule, value, callback, name);
          },
          required: true,
          trigger: 'change'
        }
      ];
    };
    return {
      txtcard_visa: this.$t('deposit.cc.field.example'),
      isdisabled: false,
      archiveStatus: false,
      TransferForm: {
        card_holder_name: '',
        expiry_month: '',
        expiry_year: '',
        importantNotes: ''
      },
      otherCcDetail: {
        cardBeginSixDigits: '',
        cardLastFourDigits: ''
      },
      withdrawable: '',
      showArchiveForm: false,
      initialCcDate: {
        expiry_month: '',
        expiry_year: ''
      },
      dialog: {
        show: false,
        text: '',
        method: ''
      },
      archiveForm: {
        cardBankLetterPath: '',
        cardStatementPath: ''
      },
      archiveFormRules: {
        cardBankLetterPath: getUploadRules('cardBankLetterPath'),
        cardStatementPath: getUploadRules('cardStatementPath')
      },
      uploadLimit: 1,
      expCardUpdateDisabled: true,
      TransferFormRules: {
        card_holder_name: [
          {
            required: true,
            message: this.$t('common.formValidation.cardOnNameReq'),
            trigger: 'blur'
          }
        ],
        expiry_month: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change'
          }
        ],
        expiry_year: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    ccDetail: {
      handler() {
        this.setDetail();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    disabledCardExpiry() {
      const { availableCard, creditCard, isExpired, isPending } = this.ccDetail;
      if (creditCard.is_del === 1) {
        return true;
      }

      if (isPending) {
        return true;
      }

      if (isExpired) {
        return false;
      }

      if (!availableCard) {
        return true;
      }

      return false;
    }
  },
  methods: {
    updateFileInfo(fileInfo, field) {
      this.archiveForm[field] = fileInfo.fileList[0];
      this.$refs['archiveForm'].validateField(field);
    },
    setDetail() {
      const creditCard = this.ccDetail.creditCard;

      if (!this.initialCcDate.expiry_month && !this.initialCcDate.expiry_year) {
        this.initialCcDate.expiry_month = creditCard.expiry_month;
        this.initialCcDate.expiry_year = creditCard.expiry_year;
      }

      this.TransferForm = {
        card_holder_name: creditCard.card_holder_name,
        expiry_month: creditCard.expiry_month,
        expiry_year: creditCard.expiry_year,
        importantNotes: ''
      };

      this.otherCcDetail = {
        cardBeginSixDigits: creditCard.card_begin_six_digits,
        cardLastFourDigits: creditCard.card_last_four_digits
      };
      this.withdrawable = this.ccDetail.balance;
    },
    getUpdatedCreditCardData() {
      const creditCardDetail = this.ccDetail.creditCard;
      return {
        id: creditCardDetail.id,
        card_holder_name: this.TransferForm.card_holder_name,
        card_begin_six_digits: creditCardDetail.card_begin_six_digits,
        card_last_four_digits: creditCardDetail.card_last_four_digits,
        expiry_month: parseInt(this.TransferForm.expiry_month),
        expiry_year: parseInt(this.TransferForm.expiry_year)
      };
    },
    confirmPrompt(type) {
      this.dialog.show = true;
      this.dialog.text = this.$t(`common.withdrawChannel.${type === 'archive' ? 'confirmArchive' : 'confirmUpdate'}`);
      this.archiveStatus = type === 'archive' ? true : false;
    },
    onArchiveSubmit() {
      this.$refs['archiveForm'].validate(valid => {
        if (valid) {
          this.confirmPrompt('archive');
        } else {
          return Promise.resolve(false);
        }
      });
    },
    onArchiveCancel() {
      this.showArchiveForm = false;
      this.resetUploadField();
    },
    onCcDateChange() {
      const isMonthChanged = parseInt(this.TransferForm.expiry_month) !== this.initialCcDate.expiry_month;
      const isYearChanged = parseInt(this.TransferForm.expiry_year) !== this.initialCcDate.expiry_year;
      if (isMonthChanged || isYearChanged) {
        this.expCardUpdateDisabled = false;
      } else {
        this.expCardUpdateDisabled = true;
      }
    },
    resetUploadField() {
      this.archiveForm = {
        cardBankLetterPath: '',
        cardStatementPath: ''
      };
    },
    archiveSubmit() {
      const data = {
        ...this.otherCcDetail,
        ...this.archiveForm,
        expiryMonth: this.TransferForm.expiry_month,
        expiryYear: this.TransferForm.expiry_year
      };
      cardAuditSubmit(data)
        .then(resp => {
          if (resp.data.code === 0) {
            this.responseMsg('success', this.$t('common.field.submitted'));
            this.resetUploadField();
            this.showArchiveForm = false;
            this.$root.$refs.topLeft.resetWithdrawalData();
          }
        })
        .catch(err => {
          this.responseMsg('error', this.$t('responseMsg.500'));
        });
    },
    onExpiredCardUpdate() {
      const updatedCcData = { ...this.getUpdatedCreditCardData() };
      console.log(updatedCcData);
      delete updatedCcData.card_holder_name;
      apiUpdateCreditCard(updatedCcData)
        .then(resp => {
          console.log('resp.data', resp.data);
          if (resp.data.code === 0 && resp.data) {
            this.responseMsg('success', this.$t('common.field.submitted'));
            this.$root.$refs.topLeft.resetWithdrawalData();
          }
        })
        .catch(err => {
          console.log('err', err);
          this.responseMsg('error', this.$t('responseMsg.500'));
        });
    },
    responseMsg(type, msg) {
      this.$message({
        message: msg,
        type: type
      });
    },
    getFormData() {
      return {
        currency: this.ccDetail.currency,
        creditCard: {
          ...this.getUpdatedCreditCardData()
        },
        importantNotes: this.TransferForm.importantNotes,
        withdrawAmount: this.ccDetail.showBalance
      };
    }
  }
};
</script>
