<template>
  <!-- Transfer Int -->
  <div class="form_bottom_row line clearfix">
    <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules" class="form_row">
      <ul class="form_list clearfix">
        <li>
          <InputForm
            :label="$t('common.field.bankName')"
            name="bankName_transferInt"
            v-model="TransferForm.bankName_transferInt"
            :disabled="isdisabled"
            testId="bankName"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('common.field.bankAddress')"
            name="bankAddress_transferInt"
            v-model="TransferForm.bankAddress_transferInt"
            :disabled="isdisabled"
            testId="bankAddress"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list clearfix">
        <li>
          <InputForm
            :label="$t('common.field.beneficiaryName')"
            name="bankBeneficiaryName_transferInt"
            v-model="TransferForm.bankBeneficiaryName_transferInt"
            :disabled="isdisabled"
            testId="beneficiaryName"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('common.field.bankAccOrIBAN')"
            name="accountNumber"
            v-model.trim="TransferForm.accountNumber"
            :disabled="isdisabled"
            testId="accountNumber"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list clearfix">
        <li>
          <InputForm
            :label="$t('common.field.bankHolderAddress')"
            name="accountHolderAddress_transferInt"
            v-model="TransferForm.accountHolderAddress_transferInt"
            :disabled="isdisabled"
            testId="holderAddress"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('common.field.swift')"
            name="swift_transferInt"
            v-model="TransferForm.swift_transferInt"
            :disabled="isdisabled"
            testId="swift"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list clearfix" v-if="userCountryCode == canCountryCode">
        <li>
          <InputForm
            label="Transit Number"
            name="transitNumber"
            testId="transitNumber"
            v-model="TransferForm.transitNumber"
          ></InputForm>
        </li>
        <li>
          <InputForm
            label="Institution Number"
            name="institutionNumber"
            v-model="TransferForm.institutionNumber"
            testId="institutionNumber"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list clearfix">
        <li>
          <InputForm
            :label="$t('common.field.abaSortCode')"
            name="abaCode_transferInt"
            v-model="TransferForm.abaCode_transferInt"
            :disabled="isdisabled"
            testId="sortCode"
          ></InputForm>
        </li>
        <li>
          <ImportantNotesInput
            :label="$t('common.field.imptNotes')"
            name="importantNotes"
            v-model="TransferForm.notes_transferInt"
            testId="importantNotes"
          ></ImportantNotesInput>
        </li>
      </ul>
      <ul class="form_list clearfix" v-if="!isdisabled">
        <li>
          <el-form-item class="upload" prop="uploadedFile">
            <div class="labelTitle">
              <span class="required_icon">*</span>
              <label for="">{{ $t('withdraw.transfer.label.upload') }}</label>
            </div>
            <vUpload
              :selfText="true"
              :limit="1"
              v-on:updateFileInfo="updateFileInfo"
              data-testid="updateFile"
            ></vUpload>
          </el-form-item>
        </li>
        <!-- <li>
          <p class="int_p" v-html="$t('withdraw.transfer.info', { platform: $config.info.fullName })"></p>
        </li> -->
      </ul>
      <div class="checkbox_wrapper" v-if="!TransferForm.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import mixin from '@/mixins';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'TransferIntWithdraw',
  props: [
    'accountNumber',
    'amount',
    'withdrawalType',
    'userCountryCode',
    'selectedCardInfo',
    'transferCountryString',
    'chooseWithdrawalType',
    'followerResults'
  ],
  components: { vUpload, ImportantNotesInput },
  mixins: [mixin, bankTransferMixin],
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      TransferForm: {
        bankName_transferInt: '',
        bankAddress_transferInt: '',
        bankBeneficiaryName_transferInt: '',
        accountNumber: '',
        accountHolderAddress_transferInt: '',
        swift_transferInt: '',
        abaCode_transferInt: '',
        notes_transferInt: '',
        transitNumber: '',
        institutionNumber: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        bankName_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'blur'
          }
        ],
        bankAddress_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: 'blur'
          }
        ],
        bankBeneficiaryName_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.beneficiaryNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        accountHolderAddress_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.accHolderAddress'),
            trigger: 'blur'
          }
        ],
        swift_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.swiftReq'),
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      canCountryCode: 4575 //Canada
    };
  },
  watch: {
    selectedCardInfo: {
      immediate: true,
      handler(value) {
        if (value && value.id !== -1) {
          var notesObj = {};
          if (value.importantNotes) {
            var properties = value.importantNotes.split(', ');
            properties.forEach(function (property) {
              var tup = property.split(':');
              notesObj[tup[0]] = tup[1];
            });
            console.log(notesObj);
          }
          this.TransferForm = {
            bankName_transferInt: value.bankName,
            bankAddress_transferInt: value.bankAddress,
            accountNumber: value.accountNumber,
            bankBeneficiaryName_transferInt: value.beneficiaryName,
            accountHolderAddress_transferInt: value.holderAddress,
            abaCode_transferInt: value.sortCode,
            swift_transferInt: value.swift,
            notes_transferInt:
              value.importantNotes && this.userCountryCode == this.canCountryCode
                ? notesObj['notes']
                : value.importantNotes && this.userCountryCode != this.canCountryCode
                ? value.importantNotes
                : '',
            userPaymentInfoId: value.id,
            transitNumber: value.importantNotes ? notesObj['Transit Number'] : '',
            institutionNumber: value.importantNotes ? notesObj['Institution Number'] : ''
          };
          this.isdisabled = true;
        } else {
          this.isdisabled = false;
          this.TransferForm.userPaymentInfoId = null;
        }
      }
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['TransferForm'].validateField('uploadedFile');
    },
    getFormData() {
      if (this.userCountryCode == this.canCountryCode) {
        this.TransferForm.notes_transferInt = `Transit Number: ${this.TransferForm.transitNumber}, Institution Number: ${this.TransferForm.institutionNumber}, notes: ${this.TransferForm.notes_transferInt}`;
      }
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        country: parseInt(this.transferCountryString),
        bankName: this.TransferForm.bankName_transferInt,
        bankAddress: this.TransferForm.bankAddress_transferInt,
        accountNumber: this.TransferForm.accountNumber,
        beneficiaryName: this.TransferForm.bankBeneficiaryName_transferInt,
        holderAddress: this.TransferForm.accountHolderAddress_transferInt,
        sortCode: this.TransferForm.abaCode_transferInt,
        swift: this.TransferForm.swift_transferInt,
        importantNotes: this.TransferForm.notes_transferInt,
        fileList: this.fileList,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>
