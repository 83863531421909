export default {
  data() {
    return { isRememberInfo: true, isdisabled: false }
  },
  methods: {
    validateAccount(rule, value, callback) {
      if (/\s/.test(value)) {
        this.TransferForm.accountNumber = this.TransferForm.accountNumber.replace(/\s+/g, '')
        callback()
      } else if (!value || value === '') {
        if (this.TransferForm.hasOwnProperty('abaCode_transferInt')) {
          //印度银行
          callback(new Error(this.$t('common.formValidation.accNumIBANReq')))
        } else {
          callback(new Error(this.$t('common.formValidation.bankAccNumReq')))
        }
      } else {
        callback()
      }
    },
  },
}
