<template>
  <!-- Thai/Mala/Viet-->
  <div id="thaiForm" class="form_bottom_row line clearfix">
    <div class="fr img_row" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
      <img :src="logImg" alt />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form_list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="numberSelection"
              testId="selectedCardID"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form_list clearfix">
            <li>
              <SelectForm
                v-model="TransferForm.bankName_thai"
                name="bankName_thai"
                :label="$t('common.field.bankName')"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="(value, name) in bankList"
                  :key="name"
                  :value="value"
                  :label="name"
                  :data-testid="name"
                ></el-option>
              </SelectForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankAddress')"
                name="bankAddress_thai"
                v-model="TransferForm.bankAddress_thai"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankBeneficiaryName')"
                name="bankBeneficiaryName_thai"
                v-model="TransferForm.bankBeneficiaryName_thai"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <InputForm
                :label="$t('common.field.bankAccNum')"
                name="accountNumber"
                v-model.trim="TransferForm.accountNumber"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <!-- only Thailand -->
            <li v-if="withdrawalType == 5">
              <InputForm
                :label="$t('common.field.swift')"
                name="Swift_thai"
                v-model="TransferForm.Swift_thai"
                :disabled="isdisabled"
              ></InputForm>
            </li>
            <li>
              <ImportantNotesInput
                :label="$t('common.field.imptNotes')"
                name="importantNotes"
                v-model="TransferForm.notes_thai"
              ></ImportantNotesInput>
            </li>
          </ul>
          <div class="checkbox_wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
              {{ $t('withdraw.default.remember') }}</el-checkbox
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'ThailandWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    followerResults: Array,
    bankList: Object
  },
  components: { ImportantNotesInput },
  mixins: [mixin, bankTransferMixin, withdrawlMixin],
  data() {
    return {
      logImg: '',
      TransferForm: {
        bankName_thai: '',
        bankAddress_thai: '',
        Swift_thai: '',
        bankBeneficiaryName_thai: '',
        accountNumber: '',
        notes_thai: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        bankName_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),

            trigger: 'blur'
          }
        ],
        bankAddress_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: 'blur'
          }
        ],
        Swift_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.swiftReq'),
            trigger: 'blur'
          }
        ],
        bankBeneficiaryName_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName_thai,
        bankAddress: this.TransferForm.bankAddress_thai,
        swift: this.TransferForm.Swift_thai,
        beneficiaryName: this.TransferForm.bankBeneficiaryName_thai,
        accountNumber: this.TransferForm.accountNumber,
        importantNotes: this.TransferForm.notes_thai,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    }
  },
  watch: {
    withdrawalType: {
      immediate: true,
      handler(type) {
        switch (this.withdrawalType) {
          case 5:
            this.logImg = require('@/assets/images/channel/thailand.png');
            break;
          case 8:
            this.logImg = require('@/assets/images/channel/vietna.png');
            break;
          case 6:
            this.logImg = require('@/assets/images/channel/vietna.png');
            break;
          default:
            break;
        }
      }
    },
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      console.log(selectedCardInfo);
      this.TransferForm = {
        selectedCardID: value,
        bankName_thai: isCurrentCard ? selectedCardInfo.bankName : '',
        bankAddress_thai: isCurrentCard ? selectedCardInfo.bankAddress : '',
        Swift_thai: isCurrentCard ? selectedCardInfo.swift : '',
        bankBeneficiaryName_thai: isCurrentCard ? selectedCardInfo.beneficiaryName : '',
        accountNumber: isCurrentCard ? selectedCardInfo.accountNumber : '',
        notes_thai: isCurrentCard ? selectedCardInfo.importantNotes : '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    },
    'TransferForm.bankBeneficiaryName_thai'(value) {
      this.TransferForm.bankBeneficiaryName_thai = this.latinNumberValidator(value);
    }
  }
};
</script>
